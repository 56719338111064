import "./src/css/animation.css";
import "./src/css/base.css";
import "./src/css/component.css";
import "./src/css/font.css";
import "./src/css/gradient.css";
import "./src/css/tailwind.css";

export function shouldUpdateScroll({ routerProps: { location } }) {
  const { hash } = location;
  if (hash === "") {
    window.scrollTo(0, 0);
  }
  return false;
}
