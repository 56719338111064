// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-case-study-contentful-case-study-slug-tsx": () => import("./../../../src/pages/caseStudy/{contentfulCaseStudy.slug}.tsx" /* webpackChunkName: "component---src-pages-case-study-contentful-case-study-slug-tsx" */),
  "component---src-pages-contact-success-tsx": () => import("./../../../src/pages/contact-success.tsx" /* webpackChunkName: "component---src-pages-contact-success-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-customer-case-tsx": () => import("./../../../src/pages/customer-case.tsx" /* webpackChunkName: "component---src-pages-customer-case-tsx" */),
  "component---src-pages-data-tsx": () => import("./../../../src/pages/data.tsx" /* webpackChunkName: "component---src-pages-data-tsx" */),
  "component---src-pages-disclaimer-tsx": () => import("./../../../src/pages/disclaimer.tsx" /* webpackChunkName: "component---src-pages-disclaimer-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-price-tsx": () => import("./../../../src/pages/price.tsx" /* webpackChunkName: "component---src-pages-price-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-product-tsx": () => import("./../../../src/pages/product.tsx" /* webpackChunkName: "component---src-pages-product-tsx" */),
  "component---src-pages-recruitment-tsx": () => import("./../../../src/pages/recruitment.tsx" /* webpackChunkName: "component---src-pages-recruitment-tsx" */),
  "component---src-pages-scenario-contentful-scenario-slug-tsx": () => import("./../../../src/pages/scenario/{ContentfulScenario.slug}.tsx" /* webpackChunkName: "component---src-pages-scenario-contentful-scenario-slug-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */)
}

