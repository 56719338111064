module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["zh-CN","en-US"],"defaultLanguage":"zh-CN","siteUrl":"https://stardust.ai/","i18nextOptions":{"nsSeparator":false,"keySeparator":false,"interpolation":{"escapeValue":false},"fallbackLng":"zh-CN"},"pages":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
